@import "~@ui/styles/variables";
@import "~@ui/styles/tools";
@import "@app/styles/variables";


.block {
    background-image: url('/next/assets/invite-pattern.jpg');
    width: 100%;
    height: 100%;

    border-radius: $border-radius;
    overflow: hidden;
    min-height: 165px;

    background-size: 150%;
    background-position: -85px -50px;
    background-repeat: repeat-y;

    padding: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include media-mobile {
        gap: 18px;

        background-size: 120%;
        background-position: -50px -30px;
    }
}

.block--title {
    font-size: 32px;
    line-height: 1.2;
    color: $color-off-white;
    font-weight: 600;

    max-width: 400px;

    @include media-tablet-portrait {
        font-size: 24px;
        max-width: 300px;
    }
}