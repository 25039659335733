@import '~@ui/styles/variables';
@import '~@ui/styles/tools';

.show-more-link {
    width: fit-content;
    height: 100%;
 

    display: flex;
    justify-content: center;
    align-items: center;
    
    transition: 0.2s background-color ease-in;

    &--default {
        padding: 12px 16px;
        border-radius: 30px;
        column-gap: 8px;
    }

    &--large {
        padding: 16px 19px;
        border-radius: 30px;
        column-gap: 10px;
    }

    &--dark {
        color: #ffffff;
        background-color: #343637;
        border: 1px solid #2b2d2e;

        &:visited {
            color: #ffffff;
        }

        &:not(:disabled):hover {
            color: #ffffff;
            text-decoration: none;
            background-color: #2b2d2e;
        }

        &:visited:not(:disabled):hover {
            color: #ffffff;
            text-decoration: none;
        }

        &:active:not(:disabled) {
            color: #ffffff;
            background-color: #2b2d2e;
            text-decoration: none;
        }

        &:visited:not(:disabled):active {
            color: #ffffff;
            text-decoration: none;
        }
    }

    &--light {
        color: $color-title-active;
        background-color: $color-off-white;
        border: 1px solid $color-off-white;

        &:visited {
            color: $color-title-active;
        }

        &:not(:disabled):hover {
            color: $color-title-active;
            text-decoration: none;
            background-color: $color-grey-light;
        }

        &:visited:not(:disabled):hover {
            color: $color-title-active;
            text-decoration: none;
        }

        &:active:not(:disabled) {
            color: $color-title-active;
            background-color: $color-grey-light;
            text-decoration: none;
        }

        &:visited:not(:disabled):active {
            color: $color-title-active;
            text-decoration: none;
        }
    }
}

.link-text {

    &--default {
        font-size: 14px;
    }

    &--large {
        font-size: 20px;
        line-height: 24px;
    }
}

@include media-tablet {
    .show-more-link {
        padding: 8px 12px;
    }

    .link-text {
        font-size: 12px;
    }
}


@include media-mobile {
    .show-more-link {
        padding: 12px 16px;
    }

    .link-text {
        font-size: 14px;
    }
}